import React from "react";
import bakuman from "../assets/BAKUMAN.png";
import "./Create.css";

function About() {
  return (
    <div className="create-page">
      <img
        className="bakuman"
        src={bakuman}
        title="toona-phone"
        alt="phone animation"
      />
      <div className="create-card">
        <h2>Become a toona creator.</h2>
        <span className="about">
          Check out the links below to learn more about how toona works and how
          to use the uploader to post your own toona.
        </span>
        <div className="button-row">
          <a
            href="https://upload.toona.io"
            className="upload-link"
            title="upload-link"
          >
            <button className="upload-btn">Upload</button>
          </a>
          <a
            href="https://createtoona.notion.site/How-to-create-a-toona-a6847edb85d842ac87e6e53ec25fdace?pvs=74"
            className="guide-link"
            title="upload-link"
          >
            <div className="link-row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#5d5c5b"
                  d="M7.5 22q-1.45 0-2.475-1.025T4 18.5v-13q0-1.45 1.025-2.475T7.5 2H20v15q-.625 0-1.062.438T18.5 18.5q0 .625.438 1.063T20 20v2zm.5-7h2V4H8zm-.5 5h9.325q-.15-.35-.237-.712T16.5 18.5q0-.4.075-.775t.25-.725H7.5q-.65 0-1.075.438T6 18.5q0 .65.425 1.075T7.5 20"
                />
              </svg>
              Explore the guides
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
