import Faq from "react-faq-component";

const data = {
  title: "FAQ ",
  rows: [
    {
      title: "What is toona?",
      content: `At toona, we're building a platform and tools for creators to make and share animated
                motion comics.`,
    },
    {
      title: "What is a motion comic?",
      content:
        "A motion comic is the combination of a comic book and an animated cartoon. " +
        "All of the assets are from the comic book, but we animate them after the fact. ",
    },
    {
      title: "How do I make a motion comic?",
      content:
        `The current technique of animating a motion comic involves a method of animation called "puppet animation". ` +
        `Using a software like Adobe After Effects and Adobe Photoshop, you cut out parts of the panels, fill in the background ` +
        `and animate the characters. This process is very time consuming and requires a lot of skill. ` +
        `We're building tools to make this process easier and more accessible to creators.`,
    },
    {
      title: "Can I post my comic to toona?",
      content: `Yes! Reach out to us through email or discord, we can do the animation for you as well! `,
    },
    {
      title: "When will toona be available?",
      content:
        "You can download the beta version of our app from the Discord server on IOS and Android. " +
        `The tools for creators will be available in the coming months. `,
    },
  ],
};

const styles = {
  // bgColor: 'white',
  titleTextColor: "#ff6666",
  rowTitleColor: "#ff6666",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  animate: true,
  // arrowIcon: "↴",
  tabFocus: true,
};

function FAQ() {
  return (
    <div className="app">
      <div className="faq-card">
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  );
}

export default FAQ;
