function Beta() {
  window.location.href = "https://discord.gg/PheSgGaedc";
  return (
    <div className="app">
      <h2>redirecting...</h2>
    </div>
  );
}

export default Beta;
