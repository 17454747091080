import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/toonalogo.png";
import "./Navbar.css";

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [classView, setClassView] = useState<string>(
    navbarOpen ? "links-mobile-visible" : "links-mobile-hidden"
  );
  const [linkClass, setLinkClass] = useState<string>("home");
  const location = useLocation();
  const [currentLink, setCurrentLink] = useState<string>("Home");
  const links = ["Home", "Create", "About", "FAQ", "Tools", "Contact"];

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrentLink("Home");
    } else {
      setCurrentLink(location.pathname.split("/")[1]);
    }
  }, [location.pathname]);

  useEffect(() => {
    setClassView(navbarOpen ? "links-mobile-visible" : "links-mobile-hidden");
  }, [navbarOpen]);

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo} height={50} width={50} alt="toona-logo"></img>
        <span className="toona">toona</span>
      </div>
      <div className="links">
        {links.map((link, index) => {
          return (
            <Link
              key={index}
              className={
                link.toLowerCase() === currentLink.toLowerCase()
                  ? "link-active"
                  : "link"
              }
              to={
                link.toLowerCase() === "home" ? "/" : `/${link.toLowerCase()}`
              }
            >
              {link}
            </Link>
          );
        })}
      </div>
      <div className={classView}>
        {links.map((link, index) => {
          return (
            <Link
              key={index}
              onClick={() => {
                setNavbarOpen(false);
              }}
              className={
                link.toLowerCase() === currentLink.toLowerCase()
                  ? "link-active"
                  : "link"
              }
              to={
                link.toLowerCase() === "home" ? "/" : `/${link.toLowerCase()}`
              }
            >
              {link}
            </Link>
          );
        })}
      </div>
      <div className="btn-container">
        <a href="https://discord.gg/PheSgGaedc" title="discord-link">
          <button className="join-btn">Join the beta!</button>
        </a>
      </div>
      <button
        title="hamburger"
        className="hamburger"
        onClick={() => {
          setNavbarOpen(!navbarOpen);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default Navbar;
