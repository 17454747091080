import React from "react";
import phoneVideo from "../assets/toona_phone_final.gif";
import "./About.css";

function About() {
  return (
    <div className="about-page">
      <div className="about-body">
        <h2 className="about-title">
          Bring your comics to the next level with
          <b> animation, voice acting, and sound.</b>
        </h2>
        <span className="about">
          Comics are amazing, but they lack the animation, music, and voice
          acting of their television/movie counterparts. We're building a
          software studio to allow comic artists to rapidly create more realized
          versions of their stories. We're also building out a platform for
          comic fans to read our new version of comics, allowing them to engage
          in their favorite stories in a dynamic, mobile-friendly fashion.
        </span>
        <div className="phone-holder">
          <img
            className="toona-phone-about"
            src={phoneVideo}
            title="toona-phone"
            alt="phone animation"
          />
        </div>
      </div>
    </div>
  );
}

export default About;
